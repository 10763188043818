export const faqCategories = {
  basic: "Algemeen",
  account: "Account",
  reservation: "Reserveren",
  cars: "Auto's",
  pricing: "Prijzen",
  community: "Community",
};
export const faqBoatCategories = {
  basic: "Algemeen",
  reservation: "Reserveren",
  boats: "Boten",
};

export const faqTaxiCategories = {
  basic: "Algemeen",
  account: "Account",
  parking: "Parkeren",
  others: "Overig",
};

export const faqs = [
  {
    id: 1,
    question:
      "Wat gebeurt er na mijn eerste gratis rit? Zijn er verborgen kosten of verplichtingen om een abonnement te nemen?",
    answer:
      "Na je eerste gratis rit blijf je genieten van onze service tegen transparante tarieven. Er zijn geen verborgen kosten en je bent niet verplicht om een abonnement te nemen. Je betaalt alleen voor de tijd dat je de auto daadwerkelijk gebruikt.",
    open: false,
    category: faqCategories.cars,
    type: "car",
  },
  {
    id: 2,
    question:
      "Is er een vast abonnement vereist en hoe zit het met het wijzigen of annuleren ervan?",
    answer: `Nee, er is geen vast abonnement vereist om van onze service gebruik te maken. Je hebt de flexibiliteit om te kiezen hoe vaak je onze deelauto’s wilt gebruiken. Wel kun je kiezen voor een van onze voordeelpakketten, waardoor je profiteert van lagere prijzen per kilometer. Je kunt jouw pakket op elk moment wijzigen of annuleren. Dit kun je zelf veranderen via jouw account in de app.`,
    open: false,
    category: faqCategories.cars,
    type: "car",
  },
  {
    id: 3,
    question: "Hoe reserveer ik een auto bij Vloto?",
    answer: `Download de Vloto app in de Google Playstore en Apple Appstore. Via deze app kun je al onze auto’s reserveren, openen en sluiten. Ook vind je hier de contactgegevens, waarmee je ons kunt bereiken wanneer zich problemen met de auto of app voordoen.`,
    open: false,
    category: faqCategories.cars,
    type: "car",
  },
  {
    id: 4,
    question: "Hoelang duurt het om mij aan te melden bij Vloto?",
    answer:
      "Binnen een paar stappen in de Vloto-app is jouw aanmelding al rond. Voor je aanmelding controleren wij jouw rijbewijs en ID. Meestal hebben we deze binnen een paar minuten al gecheckt. Daarna kun je al meteen de weg op!",
    open: false,
    category: faqCategories.cars,
    type: "car",
  },
  {
    id: 16,
    question: "Is er altijd een auto beschikbaar?",
    answer:
      "Er is altijd een Vloto bij jou in de buurt beschikbaar! Het komt bijna nooit voor dat alle auto’s in jouw buurt in gebruik zijn. Wij monitoren namelijk het gebruik van de auto’s en zetten op drukke momenten extra auto’s in. Mocht er een keer geen auto beschikbaar zijn, neem dan contact met ons op. Wij zullen dan proberen een goede oplossing te vinden en zetten indien mogelijk nog een extra auto in.",
    open: false,
    category: faqCategories.pricing,
    type: "car",
  },
  {
    id: 17,
    question: "Moet ik de auto zelf opladen?",
    answer: `Wanneer je een Vloto deelauto reserveert, staat deze opgeladen voor je klaar. Het opladen tijdens gebruik doe jij zelf. Wel maken we het je zo makkelijk mogelijk. 
      In alle Vloto auto’s is een laadpas aanwezig.
      Op de parkeerplekken van Vloto worden laadpalen geplaatst. Deze kunnen kosteloos gebruikt worden voor alle Vloto-auto’s. 
      Onderweg kun je de auto ook kosteloos opgeladen voor tarieven tot €0,40 per KwH, zowel in binnen- als buitenland. Hogere tarieven per KwH worden toegevoegd aan je rekening.
      In De Krijgsman stelt Vloto ook snelladers beschikbaar. Ook hier kunnen de Vloto auto’s kosteloos opgeladen worden`,
    open: false,
    category: faqCategories.account,
    type: "car",
  },
  {
    id: 5,
    question: "Hoelang duurt het om mij aan te melden bij Vloto?",
    answer:
      "Binnen een paar stappen in de Vloto-app is jouw aanmelding al rond. Voor je aanmelding controleren wij jouw rijbewijs en ID. Meestal hebben we deze binnen een paar minuten al gecheckt. Daarna kun je al meteen de weg op!",
    open: false,
    category: faqCategories.account,
    type: "car",
  },
  {
    id: 6,
    question: "Wat zijn de ‘communities’?",
    answer:
      "We hebben De Krijgsman opgedeeld in 4 delen; community Vestingstad Zuid, - Noord, - Oost en - West. Elke community heeft auto’s die alleen binnen de community gedeeld worden. Zodoende staan de auto’s altijd dichtbij geparkeerd, en deel je ze niet met heel veel mensen. Vanuit de community gedachte houden de gebruikers de auto’s schoon en laad je de auto op voor de volgende! ",
    open: false,
    category: faqCategories.community,
    type: "car",
  },
  {
    id: 7,
    question: "Hoe houden jullie de auto’s schoon?",
    answer:
      "Hygiëne vinden we belangrijk, dus wij maken om de paar dagen de auto’s van buiten en van binnen schoon. Mocht je de auto toch te vies vinden, kan je kosteloos met een Vloto auto door de wasstraat bij de Maxis rijden.",
    open: false,
    category: faqCategories.cars,
    type: "car",
  },
  {
    id: 8,
    question: "Wat gebeurt er als ik een boete krijg? ",
    answer:
      "Deze sturen wij door naar jou, of wordt in sommige gevallen zelfs direct aan jou gestuurd. In beide gevallen rekenen wij wel administratiekosten van €25, helaas is dit best wat werk.",
    open: false,
    category: faqCategories.basic,
    type: "car",
  },
  {
    id: 9,
    question: "Wat moet ik doen bij schade?",
    answer: `Voor schades is Vloto all-risk verzekerd. Vaak heb je zelfs recht op vervangend vervoer, in geval van een aanrijding. Neem bij schade altijd contact met ons op. 
    Bij andere gevallen, bijvoorbeeld een lege accu of een lekke band, geldt bij Vloto hetzelfde als bij een eigen auto; neem contact op met (bijvoorbeeld) de ANWB. Zij helpen je weer op weg, maar de kosten daarvoor zijn in zo’n geval wel voor jezelf (tip: wordt lid bij een wegenhulp!). Bij bijvoorbeeld een lekke band die vervangen moet worden, zullen wij de band wel betalen natuurlijk.`,
    open: false,
    category: faqCategories.basic,
    type: "car",
  },
  {
    id: 10,
    question: "Hoe verander ik de tijd van mijn reservering?",
    answer:
      "Deze kan je het makkelijkste verwijderen en opnieuw aanmaken. Is je boeking al gestart, en wil je de auto langer huren? Kijk dan eerst in de app of de auto nog beschikbaar is, door een nieuwe boeking te maken. Zo ja, kan je ons bellen en dan kunnen wij jouw reservering verlengen.",
    open: false,
    category: faqCategories.reservation,
    type: "car",
  },
  {
    id: 11,
    question: "Wat gebeurt er als ik te laat terugkom? ",
    answer:
      "Dat kan gebeuren natuurlijk, door file bijvoorbeeld. Vaak is het geen probleem. Check via de app of de auto geboekt is door een nieuwe reservering te maken. Als dit mogelijk is, dan is de auto niet geboekt. Als dit niet mogelijk is, staat er iemand op jouw auto te wachten dus bel ons dan even. Wij zijn bereikbaar op 0294 300 003.",
    open: false,
    category: faqCategories.reservation,
    type: "car",
  },
  {
    id: 12,
    question: "Kan ik een reservering (kosteloos) wijzigen of annuleren?",
    answer:
      "Ja, dat kan. Je betaalt bij Vloto alleen voor het daadwerkelijke gebruik, niet voor de reserveringstijd. Als we zien dat hier misbruik van wordt gemaakt zullen we je account blokkeren.",
    open: false,
    category: faqCategories.reservation,
    type: "car",
  },
  {
    id: 13,
    question: "Vanaf wanneer kan ik de reservering starten?",
    answer:
      "Je kan de reservering een kwartier van tevoren starten, mits de auto niet nog door iemand anders in gebruik is. ",
    open: false,
    category: faqCategories.reservation,
    type: "car",
  },
  {
    id: 14,
    question: "Waar vind ik de sleutel?",
    answer: `In de meeste auto’s zit helemaal geen sleutel. Je kan de auto openen en sluiten met de app!`,
    open: false,
    category: faqCategories.cars,
    type: "car",
  },
  {
    id: 15,
    question: "Wat kost het om een auto te delen? ",
    answer: `Op onze website hebben we een handige tool gemaakt, waarbij je een inschatting kan maken hoe veel je de auto per maand gaat gebruiken. Je ziet dan hoe veel een deelauto jou per maand kost! 
    Vloto hanteert verschillende abonnementen. De tarieven die je per maand betaalt zijn inclusief gratis uren – het is dus eigenlijk rij-tegoed! Kijk op de website voor onze actuele tarieven.`,
    open: false,
    category: faqCategories.pricing,
    type: "car",
  },

  {
    id: 18,
    question: "Hoe lang gaat de batterij mee?",
    answer:
      "Je kan ongeveer 6 uren varen op vol vermogen. Als je stil ligt gebruikt de boot natuurlijk geen stroom.",
    open: false,
    category: faqBoatCategories.boats,
    type: "boat",
  },
  {
    id: 19,
    question: "Heb ik een vaarbewijs nodig om te varen?",
    answer:
      "Het hebben van een vaarbewijs is niet nodig om te varen met onze boten. Iedereen mag met onze boten varen.",
    open: false,
    category: faqBoatCategories.basic,
    type: "boat",
  },
  {
    id: 20,
    question: "Mag ik eten en drinken mee aan boord?",
    answer:
      "Je mag eten en drinken mee aan boord, maar zorg er dan voor dat je je afval netjes opruimt. Gooi je afval dus niet in het water, en hou je aan de regels m.b.t alcohol.",
    open: false,
    category: faqBoatCategories.basic,
    type: "boat",
  },
  {
    id: 21,
    question: "Kan ik overal in Nederland varen?",
    answer:
      "Je mag niet overal in Nederland varen. Bij je bootboeking onder ‘Handige tips’, kun je een interactieve vaarkaart bekijken waarop staat aangegeven waar je wel en niet mag varen. Zo mag je bijvoorbeeld niet het Amsterdam Rijnkanaal op.",
    open: false,
    category: faqBoatCategories.basic,
    type: "boat",
  },
  {
    id: 22,
    question: "Hoe beëindig ik mijn boeking?",
    answer:
      "Op het scherm van je huidige boeking, heb je onderin een rode button om je boeking te beëindigen. Zorg hierbij wel eerst dat de boot op slot is en uit staat.",
    open: false,
    category: faqBoatCategories.reservation,
    type: "boat",
  },
  {
    id: 23,
    question: `Waar kan ik de auto’s vinden?`,
    answer: `De auto’s staan altijd geparkeerd op een parkeerplaats in de RAI. Ook moeten de auto's hier teruggebracht worden.`,
    open: false,
    category: faqTaxiCategories.basic,
    type: "taxi",
  },
  {
    id: 24,
    question: `Waar moet ik de auto terugbrengen?`,
    answer: `Op dezelfde plek waar het werd opgehaald. Via de app kan het voertuig niet worden teruggebracht naar een ander adres. 

    Het parkeergebied wordt gemarkeerd op de kaart die je in onze app ziet wanneer je een auto hebt gereserveerd.`,
    open: false,
    category: faqTaxiCategories.parking,
    type: "taxi",
  },
  {
    id: 25,
    question: `Wat heb ik nodig om gebruik te maken van de taxi’s?`,
    answer: `Om gebruik te maken van de taxi’s dien je je eerst aan te melden bij Vloto Taxi, dit kan via de app. Wij nodigen je dan uit om langs te komen bij ons op kantoor. Je hebt verder een P-nummer, ondernemerskaart en natuurlijk een volledige chauffeurskaart nodig.`,
    open: false,
    category: faqTaxiCategories.basic,
    type: "taxi",
  },
  {
    id: 26,
    question: "Wat zit er inbegrepen in de prijs?",
    answer: `Je betaalt €5 per uur voor onze taxi’s. Hierbij zit inbegrepen; 
    Een elektrische auto (volskwagen ID.4 of ID.5) met blauwe kentekens en boordcomputer, een all risk verzekering, onderhoud en schoonmaak. 
    Niet inbegrepen zijn de kosten voor de stroom, deze dien je zelf te betalen. Wij hebben snelladers beschikbaar waar je voor een laag tarief snel de auto kunt opladen`,
    open: false,
    category: faqTaxiCategories.basic,
    type: "taxi",
  },
  {
    id: 27,
    question: "Kan ik op jullie ondernemerskaart of P-nummer rijden?",
    answer: `Nee, je gebruikt altijd bij aanvang van jouw werktijd de ondernemerskaart van jouw eigen onderneming. Je rijdt dus ten allen tijde onder jouw eigen P-nummer. Als je wordt gecontroleerd terwijl je op het P-nummer van een ander rijdt, wordt je hier zwaar voor beboet. `,
    open: false,
    category: faqTaxiCategories.others,
    type: "taxi",
  },
  {
    id: 28,
    question: "Mogen anderen ook gebruik maken van de taxi’s?",
    answer: `Als je collega’s hebt met wie je de taxi wilt delen kan dit, maar zij moeten zich eerst ook hebben aangemeld bij Vloto. Laat nooit iemand anders in de auto’s rijden, want je bent dan niet verzekerd. Je moet ook altijd op je eigen P-nummer rijden.`,
    open: false,
    category: faqTaxiCategories.basic,
    type: "taxi",
  },
  {
    id: 29,
    question: "Is er altijd een auto beschikbaar?",
    answer:
      "Wij houden de groep chauffeurs die gebruik mag maken van de auto’s altijd in evenwicht met het aantal auto’s die we beschikbaar hebben. Er is dus altijd een auto beschikbaar!",
    open: false,
    category: faqTaxiCategories.basic,
    type: "taxi",
  },
];
