import React, { forwardRef, useContext } from "react";
import { InView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";
import { UserContext } from "../App";
import Ellipse from "../assets/illustrations/ellipse.png";
import EllipseLarge from "../assets/illustrations/EllipseLarge.png";
import DealLabel from "../components/DealLabel";
import { SubscriptionCard } from "../components/SubscriptionCard";
import useWindowDimensions from "../hooks/useWindowDimensions";

export const SubscriptionCalculationBlock = forwardRef(
  ({ showAppDownloadModal, city, setCurrentlyInView }, ref) => {
    const { width } = useWindowDimensions();
    const { selectedCity } = useContext(UserContext);
    const location = useLocation();

    return (
      <InView
        className="flex w-full flex-col justify-center py-[80px] px-5  md:max-w-[744px] md:px-10 lg:max-w-[1120px] lg:overflow-hidden lg:py-[140px]"
        ref={ref}
        as="div"
        threshold={width < 744 ? 0.2 : 0.4}
        onChange={(inView, entry) =>
          entry.isIntersecting && setCurrentlyInView("Tarieven")
        }
      >
        {/* <CalculationCard
          showAppDownloadModal={showAppDownloadModal}
          city={city}
        /> */}

        <div className="relative mt-10 flex flex-col items-center overflow-hidden rounded-[16px] bg-[#34432D] py-10 md:rounded-[30px] lg:mt-6 lg:w-[1120px] lg:self-center lg:pb-[85px] lg:pt-20 ">
          <div className="mb-10 flex w-[270px]  flex-col self-center lg:mb-[60px] lg:w-[708px]">
            <h3 className="mb-[10px] font-DMSans text-[24px] font-medium leading-[32px] text-white lg:mb-5 lg:text-[32px] lg:leading-[40px]">
              Nóg voordeliger rijden met Vloto?
            </h3>
            {location.pathname === "/" ? (
              <p className="text-center text-[14px] leading-[22px] text-[#EBECEA] lg:w-[660px] lg:self-center lg:text-[20px] lg:leading-[32px]">
                Kies dan een van onze <b>voordeelpakketten</b>! Daarmee bespaar
                je flink per uur. Geen verplichting, geen kleine lettertjes:
                gewoon <b>voordelig luxe rijden</b>. De tarieven kunnen afwijken
                afhankelijk van de community. Selecteer je eigen community om de
                actuele tarieven te zien.
              </p>
            ) : (
              <p className="text-center text-[14px] leading-[22px] text-[#EBECEA] lg:w-[526px] lg:self-center lg:text-[20px] lg:leading-[32px]">
                Kies dan een van onze <b>voordeelpakketten</b>! Daarmee bespaar
                je flink per uur. Geen verplichting, geen kleine lettertjes:
                gewoon <b>voordelig luxe rijden</b>.
              </p>
            )}
          </div>

          <div className="flex w-full flex-col space-y-4  px-5 lg:flex-row lg:space-y-0 lg:space-x-3 lg:px-11">
            {selectedCity.hasOwnProperty("prices") ? (
              <>
                <SubscriptionCard
                  name={"INTRO"}
                  pricePerMonth={selectedCity.prices.intro.pricePerMonth}
                  pricePerKm={selectedCity.prices.intro.pricePerKm}
                  pricePerHour={selectedCity.prices.intro.pricePerHour}
                />
                <SubscriptionCard
                  name={"BASIS"}
                  freeHours={selectedCity.prices.basis.freeHours}
                  pricePerMonth={selectedCity.prices.basis.pricePerMonth}
                  pricePerKm={selectedCity.prices.basis.pricePerKm}
                  pricePerHour={selectedCity.prices.basis.pricePerHour}
                />
                <SubscriptionCard
                  name={"PLUS"}
                  freeHours={30}
                  pricePerMonth={selectedCity.prices.plus.pricePerMonth}
                  pricePerKm={selectedCity.prices.plus.pricePerKm}
                  pricePerHour={selectedCity.prices.plus.pricePerHour}
                  recommended={false}
                />
                <SubscriptionCard
                  name={"PRO"}
                  freeHours={80}
                  pricePerMonth={selectedCity.prices.pro.pricePerMonth}
                  pricePerKm={selectedCity.prices.pro.pricePerKm}
                  pricePerHour={selectedCity.prices.pro.pricePerHour}
                />
              </>
            ) : (
              <>
                <SubscriptionCard
                  name={"INTRO"}
                  pricePerMonth={0}
                  pricePerKm={"0,24"}
                  pricePerHour={5}
                />
                <SubscriptionCard
                  name={"BASIS"}
                  freeHours={10}
                  pricePerMonth={50}
                  pricePerKm={"0,24"}
                  pricePerHour={"4,50"}
                />
                <SubscriptionCard
                  name={"PLUS"}
                  freeHours={30}
                  pricePerMonth={100}
                  pricePerKm={"0,24"}
                  pricePerHour={3}
                  recommended={false}
                />
                <SubscriptionCard
                  name={"PRO"}
                  freeHours={80}
                  pricePerMonth={200}
                  pricePerKm={"0,24"}
                  pricePerHour={"2,50"}
                />
              </>
            )}
          </div>

          <div className="z-20 mt-6 bg-vlotoGreen lg:absolute lg:bottom-0 lg:mt-0">
            <div className="lg:w-[1120px]">
              <div className="relative -mx-5 mb-[30px] flex h-[1px] items-center justify-center bg-gradient-to-r from-vlotoGreen via-[#54785A] to-vlotoGreen">
                <div className="absolute top-0 self-center lg:hidden">
                  <img src={Ellipse} alt="Ellipse" />
                </div>

                <div className="absolute top-0 right-52 hidden self-center lg:block">
                  <img
                    src={EllipseLarge}
                    className="hidden lg:block"
                    alt="Ellipse"
                  />
                </div>
              </div>

              <div className="z-40 flex flex-col lg:-ml-12 lg:w-full lg:flex-row lg:items-center lg:justify-center lg:space-x-20 lg:pb-10">
                <p className="mb-8 w-[241px] self-center text-center text-[16px] leading-[24px] text-white lg:mb-0 lg:w-[412px]">
                  Deel vandaag nog een auto met Vloto. Download de app en kies
                  het abonnement dat bij je past.
                </p>

                <div className="flex flex-col items-center justify-center space-y-3 px-9 lg:flex-row lg:space-y-0 lg:space-x-5 lg:px-0">
                  <button
                    onClick={showAppDownloadModal}
                    className="z-20 h-[48px] w-[221px] cursor-pointer rounded-[8px] bg-lightGreen font-semibold leading-[18px] text-[#2A3624]"
                  >
                    Download de app
                  </button>

                  <DealLabel />
                </div>
              </div>
            </div>
          </div>
        </div>
      </InView>
    );
  }
);
